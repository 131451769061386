<script setup>
import { ref } from 'vue';
import { Inertia } from '@inertiajs/inertia';
import useRotas from '../Composables/useRotas';

const route = useRotas();

const corBordaTop = ref(window.doulheTresConfig.configuracoes.topo_bottom_border_color);
const associacao_rodape = ref(window.doulheTresConfig.configuracoes.associacao_rodape);

const linksFooterInstitucionais = ref([
    {
        label: 'Quero vender',
        route: 'institucional.queroVender',
    },
    {
        label: 'Blog',
        route: 'institucional.links.blog',
    },
    {
        label: 'Ajuda',
        route: 'institucional.ajuda',
    },
    {
        label: 'Fale Conosco',
        route: 'institucional.faleConosco',
    },
]);

const divs = ref([
    {
        title: window.doulheTresConfig.nome,
        links: [
            {
                icon: null,
                label: 'Política de Privacidade',
                route: 'institucional.politicaPrivacidade',
            },
            {
                icon: null,
                label: 'Perguntas Frequentes (FAQ)',
                route: 'institucional.ajuda',
            },
        ],
    },
    {
        title: 'Escritório Central',
        links: [
            {
                icon: 'mdi-map-marker',
                label: window.doulheTresConfig.endereco_contato,
            },
            {
                icon: null,
                label: ' CEP: '+ window.doulheTresConfig.cep_contato,
            },
            {
                icon: 'mdi-phone-in-talk',
                label: window.doulheTresConfig.telefone_contato,
            },
            {
                icon: 'mdi-email-outline',
                label: window.doulheTresConfig.email_contato,
            },
        ],
    },
]);

const openLink = async (menu) => {
    if (!menu.route && menu.link) {
        window.open(menu.link, '_blank');
    } else {
        Inertia.visit(route(menu.route), {
            preserveScroll: false
        });
    }
}
</script>

<template>
  <div
    :class="$q.screen.lt.sm ? 'column no-wrap ' : 'row'"
    class="bg-primary text-center q-mt-xl "
    :style="corBordaTop ? `border-top: 5px solid ${corBordaTop};` : ''"
  >
    <div class="bg-primary column q-pa-lg">
      <p class="inline text-h6 text-left text-white"
      >
        Institucional
      </p>
      <a
        v-for="(linkInstitucional, index) in linksFooterInstitucionais"
        :key="index"
        class="flex items-start links text-bold cursor-pointer q-my-xs"
        @click="openLink(linkInstitucional)"
      >
        {{ linkInstitucional.label }}
      </a>
    </div>

    <div
      v-for="(div, index) in divs"
      :key="index"
      class="bg-primary childrenDivs column justify-start  text-bold align-start q-pa-lg "
    >
      <p class="inline text-h6 text-left text-white">
        {{ div.title }}
      </p>
      <a
        v-for="link in div.links"
        :key="link.label"
        class="links  q-my-xs cursor-pointer"
        @click="openLink(link)"
      >
        <q-icon
          v-if="link.icon"
          :name="link.icon"
          class="inline  q-my-xs"
        >
        </q-icon>
        {{ link.label }}
      </a>

    </div>

    <div class="bg-primary childrenDivs column justify-start  text-bold align-start q-pa-lg "
    v-if="+associacao_rodape === 1">
        <p class="inline text-h6 text-left text-white">
            Associação
        </p>
        <a target="_blank" href="http://iblo.com.br/" class="links  q-my-xs cursor-pointer">

            <img style="height: 80px" src="/logos/logotipo-iblo-1.png">
            <br/>
            Empresa afiliada ao IBLO

        </a>

    </div>

  </div>
</template>

<style lang="scss" scoped>
.childrenDivs {
  flex: 1;
}

.links {
  text-decoration: none;
  position: relative;
  color: white;
  text-align: start;
  bottom: -3px;
}

.links:after {
  content: "";
  position: absolute;
  height: 3px;
  width: 0;
  left: 0;
  background-color: var(--q-secondary);
  bottom: -3px;
  transition: 0.3s;
}

.links:hover:after {
  width: 100%;
}

.noneOutline {
  outline: none;
}
</style>
